<script lang="ts" setup>
// vue
import { defineAsyncComponent } from 'vue'

// types
import type { FetchError } from 'ofetch'

// icons
import Icons from '@/assets/icons/icons.json'

/**
 * props
 * ==================================================================
 */
const props = defineProps({
  name: {
    type: String,
    required: true,
    default: '',
    validator: val => Icons.includes(`Icon${val}`)
  },
  size: {
    type: String,
    default: '18'
  }
})

/**
 * composables
 * ==================================================================
 */
const { doHandleError } = useErrorHandler()

/**
 * defineAsyncComponent
 * ==================================================================
 */
const IconComponent = defineAsyncComponent(async () => {
  try {
    const module = await import(`../../assets/icons/Icon${props.name}.vue`)
    return module.default
  } catch (err) {
    doHandleError(err as FetchError)
  }
})
</script>

<template>
  <IconComponent
    v-if="name"
    :size="size"
  />
</template>
